// Sidebar.js
import React, { useState, useRef, useEffect } from 'react';
import { Calendar } from 'react-date-range';
import { format, addDays } from 'date-fns';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './Sidebar.css';

function Sidebar({
  startDate,
  setStartDate,
  // radius,
  // setRadius,
  filters,
  setFilters,
  accommodations,
  selectedAccommodation,
  setSelectedAccommodation,
  setMapCenter,
  // showAllEvents,
  // setShowAllEvents,
}) {
  const [accommodationDropdownVisible, setAccommodationDropdownVisible] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState(false);
  // const [radiusDropdownVisible, setRadiusDropdownVisible] = useState(false);
  const calendarRef = useRef(null);
  const accommodationRef = useRef(null);
  // const radiusRef = useRef(null);

  const eventIcons = {
    Zene: {
      colorless: '/wp-content/icons/microphone_colorless.png',
      colored: '/wp-content/icons/microphone.png',
    },
    Kultúra: {
      colorless: '/wp-content/icons/museum_colorless.png',
      colored: '/wp-content/icons/museum.png',
    },
    Sport: {
      colorless: '/wp-content/icons/sport_colorless.png',
      colored: '/wp-content/icons/sport.png',
    },
    Színház: {
      colorless: '/wp-content/icons/theatre_colorless.png',
      colored: '/wp-content/icons/theatre.png',
    },
  };

  const handleAccommodationClick = (accommodation) => {
    setSelectedAccommodation(selectedAccommodation === accommodation ? null : accommodation);
    setMapCenter([accommodation.longitude, accommodation.latitude]); // Corrected here
    setAccommodationDropdownVisible(false);
  };

  const handleEventTypeClick = (type) => {
    setFilters({ ...filters, [type]: !filters[type] });
  };

  // Removed showAllEvents handler
  /*
  const handleShowAllEventsClick = () => {
    setShowAllEvents(!showAllEvents);
    setRadius(showAllEvents ? 5 : 50);
    setRadiusDropdownVisible(false);
  };
  */

  const handleClickOutside = (event) => {
    if (calendarRef.current && !calendarRef.current.contains(event.target)) {
      setCalendarOpen(false);
    }
    if (accommodationRef.current && !accommodationRef.current.contains(event.target)) {
      setAccommodationDropdownVisible(false);
    }
    // if (radiusRef.current && !radiusRef.current.contains(event.target)) {
    //   setRadiusDropdownVisible(false);
    // }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="sidebar-container">
      <div className="section accommodation-section" ref={accommodationRef}>
        <button
          className={`dropdown-button ${selectedAccommodation ? 'active' : ''}`}
          onClick={() => setAccommodationDropdownVisible(!accommodationDropdownVisible)}
        >
          {selectedAccommodation
            ? selectedAccommodation.szallas_nev
            : 'Válassz a szállásaid közül!'}
        </button>
        {accommodationDropdownVisible && (
          <div className="dropdown-list accommodation-dropdown">
            {accommodations.length > 0 ? (
              accommodations.map((accommodation) => (
                <div
                  key={accommodation.id}
                  className={`accommodation-item ${
                    selectedAccommodation === accommodation ? 'active' : ''
                  }`}
                  onClick={() => handleAccommodationClick(accommodation)}
                >
                  <strong>{accommodation.szallas_nev}</strong>
                  <br />
                  <small>{accommodation.szallas_cim}</small>
                </div>
              ))
            ) : (
              <p>No accommodations to show.</p>
            )}
          </div>
        )}
      </div>
      <div className="section calendar-section" ref={calendarRef}>
        <div className="date-inputs" onClick={() => setCalendarOpen(!calendarOpen)}>
          <input type="text" value={format(startDate, 'yyyy-MM-dd')} readOnly />
        </div>
        {calendarOpen && (
          <div className="calendar-dropdown">
            <Calendar
              date={startDate}
              onChange={(date) => setStartDate(date)}
              minDate={new Date()}
              maxDate={addDays(new Date(), 365)}
            />
          </div>
        )}
      </div>
      <div className="section event-filter-section">
        <div className="event-filter-types">
          {Object.keys(eventIcons).map((type) => (
            <button
              key={type}
              className={`event-icon-button ${filters[type] ? 'active' : ''}`}
              onClick={() => handleEventTypeClick(type)}
              style={{
                backgroundImage: `url(${
                  filters[type] ? eventIcons[type].colored : eventIcons[type].colorless
                })`,
              }}
            />
          ))}
        </div>
      </div>
      {/* Commented out Range Section */}
      {/*
      <div className="section range-section" ref={radiusRef}>
        <label> </label>
        <div className="range-button-section">
          <button
            className={`show-all-events-button ${showAllEvents ? 'active' : ''}`}
            onClick={handleShowAllEventsClick}
          >
            {showAllEvents ? 'Hatósugaras nézet' : 'Budapest nézet'}
          </button>
          <button
            className={`range-button ${showAllEvents ? 'disabled' : ''}`}
            onClick={() => !showAllEvents && setRadiusDropdownVisible(!radiusDropdownVisible)}
            disabled={showAllEvents}
          >
            {showAllEvents ? 'X' : `${radius} km körzet`}
          </button>
          {radiusDropdownVisible && (
            <div className="range-dropdown">
              <input
                type="range"
                min="1"
                max="10"
                step="1"
                value={radius}
                onChange={(e) => setRadius(parseInt(e.target.value))}
              />
              <span>{radius} km</span>
            </div>
          )}
        </div>
      </div>
      */}
    </div>
  );
}

export default Sidebar;
