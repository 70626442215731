// App.js

import React, { useEffect, useState } from 'react';
import './App.css';
import './styles/Animations.css'; // Import the animation styles
import Sidebar from './components/Sidebar';
import MapSection from './components/MapSection';
import BottomSection from './components/BottomSection';
import SuggestionSection from './components/SuggestionSection'; // Import the new component
import axios from 'axios';
import {
  startOfDay,
  endOfDay,
  parseISO,
  isWithinInterval,
  isValid,
} from 'date-fns';

// Utility functions for distance calculation
function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1); // Corrected order
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // Distance in km
}

function deg2rad(deg) {
  return (deg * Math.PI) / 180;
}

function App() {
  const [startDate, setStartDate] = useState(startOfDay(new Date()));
  // For testing, set startDate to a date with a holiday
  // const [startDate, setStartDate] = useState(startOfDay(new Date('2024-10-11')));

  const [filters, setFilters] = useState({
    Zene: false,
    Kultúra: false,
    Sport: false,
    Színház: false,
  });
  const [accommodations, setAccommodations] = useState([]);
  const [events, setEvents] = useState([]);
  const [venues, setVenues] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [filteredVenues, setFilteredVenues] = useState([]);
  const [selectedAccommodation, setSelectedAccommodation] = useState(null);
  const [mapCenter, setMapCenter] = useState([19.0402, 47.4979]); // [longitude, latitude]
  const [selectedEvent, setSelectedEvent] = useState(null);

  // New state variables for SuggestionSection
  const [eventsCount, setEventsCount] = useState(0);
  const [eventImportance, setEventImportance] = useState(0);
  const [holidayNames, setHolidayNames] = useState([]);
  const [holidayEventInteraction, setHolidayEventInteraction] = useState(0);
  const [holidayEventImportance, setHolidayEventImportance] = useState(0);
  const [dayOfWeek, setDayOfWeek] = useState(0);
  const [isWeekend, setIsWeekend] = useState(0);
  const [daysToNextHoliday, setDaysToNextHoliday] = useState(0);
  const prevDayPriceChange = 0; // Set to 0 as per instructions
  const prev3DayAvgPriceChange = 0; // Set to 0 as per instructions

  // New state variable for toggling BottomSection on mobile
  const [isBottomSectionOpen, setIsBottomSectionOpen] = useState(true);

  // Fetch events, venues, accommodations, and holidays on initial load
  useEffect(() => {
    const fetchData = async () => {
      // Fetch Venues
      try {
        const venuesResponse = await axios.get('/wp-json/wp/v2/custom_venues');
        setVenues(venuesResponse.data || []);
        console.log('Fetched Venues:', venuesResponse.data);
      } catch (error) {
        console.error('Error fetching venues:', error);
        setVenues([]);
      }

      // Fetch Accommodations
      try {
        const accommodationsResponse = await axios.get(
          '/wp-json/wp/v2/user_accommodations',
          {
            headers: { 'X-WP-Nonce': window.WPAPI?.nonce },
          }
        );
        setAccommodations(accommodationsResponse.data || []);
        console.log('Fetched Accommodations:', accommodationsResponse.data);
      } catch (error) {
        console.error('Error fetching accommodations:', error);
        setAccommodations([]);
      }

      // Fetch Events
      try {
        const eventsResponse = await axios.get('/wp-json/wp/v2/events');
        setEvents(eventsResponse.data || []);
        console.log('Fetched Events:', eventsResponse.data);
      } catch (error) {
        console.error('Error fetching events:', error);
        setEvents([]);
      }

      // Fetch Holidays
      try {
        const holidaysResponse = await axios.get('/wp-json/wp/v2/holidays');
        setHolidays(holidaysResponse.data || []);
        console.log('Fetched Holidays:', holidaysResponse.data);
      } catch (error) {
        console.error('Error fetching holidays:', error);
        setHolidays([]);
      }
    };

    fetchData();
  }, []);

  // Filter events and set filteredVenues
  useEffect(() => {
    if (venues.length > 0 && events.length > 0 && selectedAccommodation) {
      const accommodationLatitude = parseFloat(selectedAccommodation.latitude);
      const accommodationLongitude = parseFloat(selectedAccommodation.longitude);

      const selectedDayStart = startOfDay(startDate);
      const selectedDayEnd = endOfDay(startDate);

      // Filter events based on the selected date
      let filteredEvents = events.filter((event) => {
        const eventStartStr = event.event_start.replace(' ', 'T');
        const eventDate = parseISO(eventStartStr);

        if (!isValid(eventDate)) {
          console.warn('Invalid event date:', event.event_start);
          return false;
        }

        return isWithinInterval(eventDate, {
          start: selectedDayStart,
          end: selectedDayEnd,
        });
      });

      // Filter events based on selected filters
      const activeFilters = Object.keys(filters).filter((key) => filters[key]);
      if (activeFilters.length > 0) {
        filteredEvents = filteredEvents.filter((event) =>
          activeFilters.includes(event.event_type)
        );
      }

      // Map venues with their events and calculate distance
      const filteredVenuesData = venues
        .map((venue) => {
          const venueLatitude = parseFloat(venue.latitude);
          const venueLongitude = parseFloat(venue.longitude);
          const distanceInKm = getDistanceFromLatLonInKm(
            accommodationLatitude,
            accommodationLongitude,
            venueLatitude,
            venueLongitude
          );

          const eventsAtVenue = filteredEvents.filter(
            (event) => event.event_venue === venue.venue_name
          );

          return {
            ...venue,
            distanceInKm,
            events: eventsAtVenue,
          };
        })
        .filter((venue) => venue.events.length > 0);

      setFilteredVenues(filteredVenuesData);
    } else {
      setFilteredVenues([]);
    }
  }, [
    venues,
    events,
    startDate,
    selectedAccommodation,
    filters,
  ]);

// Compute variables for SuggestionSection
  useEffect(() => {
    if (
      events.length >= 0 &&
      selectedAccommodation &&
      holidays.length >= 0 &&
      venues.length >= 0
    ) {
      // Compute eventsCount
      const selectedDayStart = startOfDay(startDate);
      const selectedDayEnd = endOfDay(startDate);

      const eventsOnSelectedDate = events.filter((event) => {
        const eventStartStr = event.event_start.replace(' ', 'T');
        const eventDate = parseISO(eventStartStr);

        if (!isValid(eventDate)) {
          console.warn('Invalid event date:', event.event_start);
          return false;
        }

        return isWithinInterval(eventDate, {
          start: selectedDayStart,
          end: selectedDayEnd,
        });
      });

      const eventsCountValue = eventsOnSelectedDate.length;
      setEventsCount(eventsCountValue);
      console.log('Events Count:', eventsCountValue); // Debugging

      // Compute eventImportance
      let totalAdjustedRating = 0;

      eventsOnSelectedDate.forEach((event) => {
        const venue = venues.find((v) => v.venue_name === event.event_venue);
        if (venue) {
          const venueLongitude = parseFloat(venue.longitude);
          const venueLatitude = parseFloat(venue.latitude);
          const accommodationLongitude = parseFloat(
            selectedAccommodation.longitude
          );
          const accommodationLatitude = parseFloat(
            selectedAccommodation.latitude
          );

          const distanceInKm = getDistanceFromLatLonInKm(
            accommodationLatitude,
            accommodationLongitude,
            venueLatitude,
            venueLongitude
          );

          let distanceFactor = 1.01;
          if (distanceInKm >= 1 && distanceInKm <= 10) {
            distanceFactor = 1.01 + ((distanceInKm - 1) * 0.09) / 9;
          } else if (distanceInKm > 10) {
            distanceFactor = 1.1; // Max factor
          }

          const eventRating = parseFloat(event.event_rating) || 0;
          const adjustedRating = eventRating / distanceFactor;

          totalAdjustedRating += adjustedRating;
        }
      });

      // Multiply totalAdjustedRating by 1.5 before dividing
      totalAdjustedRating = totalAdjustedRating * 1.5;

      const eventImportanceValue =
        eventsCountValue > 0 ? totalAdjustedRating / eventsCountValue : 0;
      setEventImportance(eventImportanceValue);
      console.log('Event Importance:', eventImportanceValue); // Debugging

      // Compute holidayNames
      const holidaysOnSelectedDate = holidays.filter((holiday) => {
        const holidayStart = parseISO(holiday.start_date);
        const holidayEnd = holiday.end_date
          ? parseISO(holiday.end_date)
          : holidayStart;

        if (!isValid(holidayStart)) {
          console.warn('Invalid holiday start date:', holiday.start_date);
          return false;
        }

        if (!isValid(holidayEnd)) {
          console.warn('Invalid holiday end date:', holiday.end_date);
          return false;
        }

        return isWithinInterval(selectedDayStart, {
          start: startOfDay(holidayStart),
          end: endOfDay(holidayEnd),
        });
      });

      const holidayNamesValue = holidaysOnSelectedDate.map(
        (holiday) => holiday.holiday
      );
      setHolidayNames(holidayNamesValue);
      console.log('Holidays Count:', holidayNamesValue.length); // Debugging

      // Compute dayOfWeek (1 for Monday, 7 for Sunday)
      const jsDayOfWeek = startDate.getDay(); // 0 (Sunday) to 6 (Saturday)
      const dayOfWeekValue = jsDayOfWeek === 0 ? 7 : jsDayOfWeek;
      setDayOfWeek(dayOfWeekValue);
      console.log('Day of Week:', dayOfWeekValue); // Debugging

      // Compute isWeekend
      const isWeekendValue = dayOfWeekValue >= 6 ? 1 : 0;
      setIsWeekend(isWeekendValue);
      console.log('Is Weekend:', isWeekendValue); // Debugging

      // Compute holidayEventInteraction
      let totalChange = 0;
      holidaysOnSelectedDate.forEach((holiday) => {
        const changeValue = parseFloat(holiday.change) || 0;
        totalChange += changeValue; // Do not divide changeValue by 10
      });

      const numberOfHolidays = holidaysOnSelectedDate.length;
      const holidayEventInteractionValue =
        numberOfHolidays > 0 ? totalChange / numberOfHolidays : 0;
      setHolidayEventInteraction(holidayEventInteractionValue);
      console.log(
        'Holiday Event Interaction:',
        holidayEventInteractionValue
      ); // Debugging

      // Compute holidayEventImportance
      const holidayEventImportanceValue =
        eventImportanceValue !== 0
          ? holidayEventInteractionValue / eventImportanceValue
          : 0;
      setHolidayEventImportance(holidayEventImportanceValue);
      console.log(
        'Holiday Event Importance:',
        holidayEventImportanceValue
      ); // Debugging

      // Compute daysToNextHoliday
      const futureHolidays = holidays
        .map((holiday) => {
          const holidayStart = startOfDay(parseISO(holiday.start_date));

          return {
            ...holiday,
            holidayStart,
          };
        })
        .filter((holiday) => {
          if (!isValid(holiday.holidayStart)) {
            console.warn('Invalid holiday start date:', holiday.start_date);
            return false;
          }

          return holiday.holidayStart > startDate; // Exclude holidays on or before the selected date
        });

      futureHolidays.sort((a, b) => {
        return a.holidayStart - b.holidayStart;
      });

      const nextHoliday = futureHolidays[0];

      const daysToNextHolidayValue = nextHoliday
        ? Math.ceil(
            (nextHoliday.holidayStart - startDate) / (1000 * 60 * 60 * 24)
          )
        : 0; // If no future holidays, set to 0

      setDaysToNextHoliday(daysToNextHolidayValue);
      console.log('Days to Next Holiday:', daysToNextHolidayValue); // Debugging
    }
  }, [
    events,
    startDate,
    selectedAccommodation,
    holidays,
    venues,
  ]);



  return (
    <div className="App">
      <Sidebar
        startDate={startDate}
        setStartDate={setStartDate}
        filters={filters}
        setFilters={setFilters}
        accommodations={accommodations}
        selectedAccommodation={selectedAccommodation}
        setSelectedAccommodation={setSelectedAccommodation}
        setMapCenter={setMapCenter}
      />

      <div
        className={`main-content-horizontal ${
          isBottomSectionOpen ? 'bottom-open' : 'bottom-closed'
        }`}
      >
        <BottomSection
          filteredVenues={filteredVenues}
          setMapCenter={setMapCenter}
          setSelectedEvent={setSelectedEvent}
          holidays={holidays} // Pass holidays to BottomSection
          startDate={startDate} // Pass startDate to filter holidays
        />

        {/* Toggle Button */}
        <div
          className="toggle-button"
          onClick={() => setIsBottomSectionOpen(!isBottomSectionOpen)}
        >
          {isBottomSectionOpen ? '<' : '>'}
        </div>

        <MapSection
          selectedAccommodation={selectedAccommodation}
          accommodations={accommodations}
          filteredVenues={filteredVenues}
          mapCenter={mapCenter}
          setMapCenter={setMapCenter}
          selectedEvent={selectedEvent}
          setSelectedEvent={setSelectedEvent}
          isBottomSectionOpen={isBottomSectionOpen} // Pass the prop
        />
      </div>

      <SuggestionSection
        eventsCount={eventsCount}
        month={startDate.getMonth() + 1}
        day={startDate.getDate()}
        holiday={holidayNames.length} // Pass the count of holidays
        eventImportance={eventImportance}
        dayOfWeek={dayOfWeek}
        holidayEventInteraction={holidayEventInteraction}
        holidayEventImportance={holidayEventImportance}
        prevDayPriceChange={prevDayPriceChange} // As per instruction
        prev3DayAvgPriceChange={prev3DayAvgPriceChange} // As per instruction
        isWeekend={isWeekend}
        daysToNextHoliday={daysToNextHoliday}
      />
    </div>
  );
}

export default App;
