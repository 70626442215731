// SuggestionSection.js

import React, { useState } from 'react';
import axios from 'axios';
import './SuggestionSection.css';

function SuggestionSection({
  eventsCount,
  month,
  day,
  holiday,
  eventImportance,
  dayOfWeek,
  holidayEventInteraction,
  holidayEventImportance,
  prevDayPriceChange,
  prev3DayAvgPriceChange,
  isWeekend,
  daysToNextHoliday,
}) {
  const [priceSuggestion, setPriceSuggestion] = useState(null);
  const [isSuggestionBoxVisible, setIsSuggestionBoxVisible] =
    useState(false);

  // Function to handle clicking the "Get Price Suggestion" button
  const handleGetPriceSuggestion = async () => {
    try {
      const requestData = {
        events_count: eventsCount,
        month,
        day,
        holiday,
        event_importance: eventImportance,
        day_of_week: dayOfWeek,
        holiday_event_interaction: holidayEventInteraction,
        holiday_event_importance: holidayEventImportance,
        prev_day_price_change: prevDayPriceChange,
        prev_3_day_avg_price_change: prev3DayAvgPriceChange,
        is_weekend: isWeekend,
        days_to_next_holiday: daysToNextHoliday,
      };

      console.log('Sending data to backend:', requestData);

      // Send the request to Flask backend
      const response = await axios.post(
        'https://db74-139-144-66-74.ngrok-free.app/get_price_suggestion',
        requestData
      );

 
      // Log the response to see what the backend returns
      console.log('Response from API:', response);

      // Make sure the response is in the expected format
      if (response.data && response.data.suggestion !== undefined) {
        setPriceSuggestion(response.data.suggestion);
        setIsSuggestionBoxVisible(true); // Show the suggestion box
      } else {
        console.error('Unexpected response format:', response);
      }
    } catch (error) {
      console.error('Error fetching price suggestion:', error);
    }
  };

  return (
    <div className="suggestion-section">
      <div className="suggestion-button-container">
        <button onClick={handleGetPriceSuggestion}>
          Kérem az ajánlatot!
        </button>
      </div>
      {isSuggestionBoxVisible && (
        <div className="suggestion-box">
          <h2>Ajánlott ár módosítás:</h2>
          <p>
            {priceSuggestion !== null
              ? `+${priceSuggestion.toFixed(2)}%`
              : 'Loading...'}
          </p>
        </div>
      )}
    </div>
  );
}

export default SuggestionSection;