import React, { useState } from 'react';
import './BottomSection.css';
import {
  startOfDay,
  endOfDay,
  parseISO,
  isWithinInterval,
  isValid,
  format,
} from 'date-fns';

function BottomSection({ filteredVenues, setMapCenter, setSelectedEvent, holidays, startDate }) {
  const [activeEvent, setActiveEvent] = useState(null);

  const eventIcons = {
    Zene: {
      colorless: '/wp-content/icons/microphone_colorless.png',
      colored: '/wp-content/icons/microphone.png',
    },
    Kultúra: {
      colorless: '/wp-content/icons/museum_colorless.png',
      colored: '/wp-content/icons/museum.png',
    },
    Sport: {
      colorless: '/wp-content/icons/sport_colorless.png',
      colored: '/wp-content/icons/sport.png',
    },
    Színház: {
      colorless: '/wp-content/icons/theatre_colorless.png',
      colored: '/wp-content/icons/theatre.png',
    },
  };

  // Modified sorting function
  const sortedEvents = filteredVenues
    .reduce((acc, venue) => {
      venue.events.forEach((event) => {
        acc.push({ ...event, venue });
      });
      return acc;
    }, [])
    .sort((a, b) => {
      // Compare event_rating in descending order
      const ratingA = a.event_rating || 0;
      const ratingB = b.event_rating || 0;

      if (ratingB !== ratingA) {
        return ratingB - ratingA; // Descending order
      } else {
        // If ratings are equal, compare event_start in ascending order
        return new Date(a.event_start) - new Date(b.event_start);
      }
    });

  // Filter holidays based on selected date
  const selectedDayStart = startOfDay(startDate);
  const selectedDayEnd = endOfDay(startDate);

  const holidaysOnSelectedDate = holidays.filter((holiday) => {
    const holidayStart = parseISO(holiday.start_date);
    const holidayEnd = holiday.end_date ? parseISO(holiday.end_date) : holidayStart;

    if (!isValid(holidayStart)) {
      console.warn('Invalid holiday start date:', holiday.start_date);
      return false;
    }

    if (!isValid(holidayEnd)) {
      console.warn('Invalid holiday end date:', holiday.end_date);
      return false;
    }

    return isWithinInterval(selectedDayStart, {
      start: startOfDay(holidayStart),
      end: endOfDay(holidayEnd),
    });
  });

  // Group holidays by holiday name
  const holidayGroups = holidaysOnSelectedDate.reduce((groups, holiday) => {
    const name = holiday.holiday;
    if (!groups[name]) {
      groups[name] = [];
    }
    groups[name].push(holiday);
    return groups;
  }, {});

  // Map holiday groups to match the event structure
  const holidayEvents = Object.keys(holidayGroups).map((holidayName) => {
    const holidays = holidayGroups[holidayName];
    // Combine countries
    const countries = holidays.map((h) => h.country);
    const uniqueCountries = [...new Set(countries)]; // Remove duplicates
    const countryText = uniqueCountries.join(', ');

    // Use the earliest start date and latest end date among the group
    const startDates = holidays.map((h) => parseISO(h.start_date));
    const endDates = holidays.map((h) =>
      h.end_date ? parseISO(h.end_date) : parseISO(h.start_date)
    );
    const earliestStart = new Date(Math.min(...startDates));
    const latestEnd = new Date(Math.max(...endDates));

    return {
      id: `holiday-${holidayName}`, // Use holiday name as unique ID
      event_name: holidayName, // Use holiday name
      event_type: 'Holiday', // Use 'Holiday' as the type
      event_venue: countryText, // List of countries
	event_start: format(earliestStart, 'yyyy-MM-dd'),
	event_end: format(latestEnd, 'yyyy-MM-dd'),
      event_rating: null, // No rating for holidays
      isHoliday: true, // Custom flag to identify holidays
      countries: uniqueCountries, // Store countries separately if needed
    };
  });

  // Combine holidayEvents and sortedEvents
  const combinedEvents = [...holidayEvents, ...sortedEvents].sort((a, b) => {
    // Place holidays at the top
    if (a.isHoliday && !b.isHoliday) {
      return -1;
    } else if (!a.isHoliday && b.isHoliday) {
      return 1;
    } else if (a.isHoliday && b.isHoliday) {
      // If both are holidays, sort by start date
      return new Date(a.event_start) - new Date(b.event_start);
    } else {
      // Existing sorting for events
      const ratingA = a.event_rating || 0;
      const ratingB = b.event_rating || 0;

      if (ratingB !== ratingA) {
        return ratingB - ratingA; // Descending order
      } else {
        // If ratings are equal, compare event_start in ascending order
        return new Date(a.event_start) - new Date(b.event_start);
      }
    }
  });

  const handleEventClick = (event) => {
    if (event.isHoliday) return; // Do nothing for holidays

    setSelectedEvent(event);
    setActiveEvent(activeEvent === event.id ? null : event.id);

    const longitude = parseFloat(event.venue.longitude);
    const latitude = parseFloat(event.venue.latitude);

    setMapCenter([longitude, latitude]);
  };

  return (
    <div className="bottom-container">
      <div className="events">
        <h2>Események a közelben</h2>
        <div className="content">
          {combinedEvents.length > 0 ? (
            combinedEvents.map((event) => {
              if (event.isHoliday) {
                // Render holidays
                const datesText =
                  event.event_end && event.event_end !== event.event_start
                    ? `${event.event_start} - ${event.event_end}`
                    : event.event_start;

                return (
                  <div
                    key={event.id}
                    className="holiday-item"
                    style={{ backgroundColor: '#a14343' }} // Updated background color
                  >
                    <div className="holiday-text">
                      <strong>{event.event_name}</strong>
                      <br />
                      {event.countries.length > 1 ? (
                        <marquee behavior="scroll" direction="left">
                          <small>{event.event_venue}</small>
                        </marquee>
                      ) : (
                        <small>{event.event_venue}</small>
                      )}
                      <br />
                      <div className="event-dates">{datesText}</div>
                    </div>
                  </div>
                );
              } else {
                // Render events
                const icon = eventIcons[event.event_type] || {};
                const backgroundImage =
                  activeEvent === event.id ? icon.colored : icon.colorless;
                const datesText = new Date(event.event_start).toLocaleDateString(
                  'hu-HU',
                  {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                  }
                );

                return (
                  <button
                    key={event.id}
                    className={`event-button ${
                      activeEvent === event.id ? 'active' : ''
                    }`}
                    onClick={() => handleEventClick(event)}
                    style={{ '--background-image': `url(${backgroundImage})` }}
                  >
                    <div className="event-text">
                      <strong>{event.event_name}</strong>
                      <br />
                      <small>{event.event_venue}</small>
                      <br />
                      <div className="event-dates">{datesText}</div>
                    </div>
                  </button>
                );
              }
            })
          ) : (
            <p className="info-text">Nincsenek események.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default BottomSection;
